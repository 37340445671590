"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailServiceMessage = void 0;
const typebox_1 = require("@sinclair/typebox");
const email_send_message_1 = require("./email-send-message");
const email_transmitted_message_1 = require("./email-transmitted-message");
exports.EmailServiceMessage = typebox_1.Type.Union([
    email_transmitted_message_1.EmailBatchErrorEvent,
    email_send_message_1.EmailMessageEvent,
    email_transmitted_message_1.EmailStorageErrorEvent,
    email_transmitted_message_1.EmailTransmissionErrorEvent,
    email_transmitted_message_1.EmailTransmittedEvent,
], { $id: 'EmailServiceMessage' });
