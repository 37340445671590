"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDynamoMapper = getDynamoMapper;
exports.getJsonMapper = getJsonMapper;
const account_1 = require("./account");
const asset_1 = require("./asset");
const asset_site_1 = require("./asset-site");
const attendee_1 = require("./attendee");
const component_1 = require("./component");
const connection_1 = require("./connection");
const core_1 = require("./core");
const domain_1 = require("./domain");
const email_1 = require("./email");
const guest_1 = require("./guest");
const guest_external_1 = require("./guest-external");
const guest_session_1 = require("./guest-session");
const module_1 = require("./module");
const organization_1 = require("./organization");
const rate_limit_request_1 = require("./rate-limit-request");
const show_1 = require("./show");
const show_instruction_1 = require("./show-instruction");
const site_1 = require("./site");
const site_export_1 = require("./site-export");
const site_variable_1 = require("./site-variable");
const timestamp_json_1 = require("./timestamp-json");
const DynamoMapper = {
    Account: account_1.AccountDynamoMapper,
    AssetSiteAssociation: asset_site_1.AssetSiteAssociationDynamoMapper,
    Attendee: attendee_1.AttendeeDynamoMapper,
    Component: component_1.ComponentDynamoMapper,
    Connection: connection_1.ConnectionDynamoMapper,
    Core: core_1.CoreDynamoMapper,
    Domain: domain_1.DomainDynamoMapper,
    EmailBatch: email_1.EmailBatchDynamoMapper,
    EmailGuestSend: email_1.EmailGuestSendDynamoMapper,
    EmailTransaction: email_1.EmailTransactionDynamoMapper,
    EmailTrigger: email_1.EmailTriggerDynamoMapper,
    Guest: guest_1.GuestDynamoMapper,
    GuestExternal: guest_external_1.GuestExternalDynamoMapper,
    GuestSession: guest_session_1.GuestSessionDynamoMapper,
    Module: module_1.ModuleDynamoMapper,
    Organization: organization_1.OrganizationDynamoMapper,
    RateLimitRequest: rate_limit_request_1.RateLimitRequestDynamoMapper,
    Show: show_1.ShowDynamoMapper,
    ShowInstruction: show_instruction_1.ShowInstructionDynamoMapper,
    Site: site_1.SiteDynamoMapper,
    SiteExport: site_export_1.SiteExportDynamoMapper,
    SiteVariable: site_variable_1.SiteVariableDynamoMapper,
};
const JsonMapper = {
    Account: account_1.AccountJsonMapper,
    Asset: asset_1.AssetJsonMapper,
    AssetOutput: asset_1.OutputJsonMapper,
    AssetSiteAssociation: asset_site_1.AssetSiteAssociationJsonMapper,
    Attendee: attendee_1.AttendeeJsonMapper,
    AudioConfig: asset_1.AudioConfigJsonMapper,
    CaptionConfig: asset_1.CaptionConfigJsonMapper,
    Component: component_1.ComponentJsonMapper,
    Connection: connection_1.ConnectionJsonMapper,
    Core: core_1.CoreJsonMapper,
    Domain: domain_1.DomainJsonMapper,
    EmailBatch: email_1.EmailBatchJsonMapper,
    EmailGuestSend: email_1.EmailGuestSendJsonMapper,
    EmailTransaction: email_1.EmailTransactionJsonMapper,
    EmailTrigger: email_1.EmailTriggerJsonMapper,
    Guest: guest_1.GuestJsonMapper,
    GuestExternal: guest_external_1.GuestExternalJsonMapper,
    GuestSession: guest_session_1.GuestSessionJsonMapper,
    Module: module_1.ModuleJsonMapper,
    Organization: organization_1.OrganizationJsonMapper,
    RateLimitRequest: rate_limit_request_1.RateLimitRequestJsonMapper,
    Show: show_1.ShowJsonMapper,
    ShowInstruction: show_instruction_1.ShowInstructionJsonMapper,
    Site: site_1.SiteJsonMapper,
    SiteExport: site_export_1.SiteExportJsonMapper,
    SiteVariable: site_variable_1.SiteVariableJsonMapper,
    Stream: asset_1.StreamJsonMapper,
    Timestamps: timestamp_json_1.TimestampsJsonMapper,
    Transfer: asset_1.TransferJsonMapper,
    Watermark: asset_1.WatermarkJsonMapper,
};
/**
 * Retrieve a `DynamoDbMapper` for the given `kind`
 */
function getDynamoMapper(kind) {
    return DynamoMapper[kind];
}
/**
 * Retrieve a `JsonMapper` for the given `kind`
 */
function getJsonMapper(kind) {
    return JsonMapper[kind];
}
