"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailTransmissionErrorEvent = exports.EmailStorageErrorEvent = exports.EmailBatchErrorEvent = exports.EmailTransmittedEvent = void 0;
const typebox_1 = require("@sinclair/typebox");
const email_external_service_1 = require("../../model/email/email-external-service");
const message_source_1 = require("../message-source");
const tracing_1 = require("../tracing");
/**
 * Event Bridge message indicating messages of a batch were successfully
 * transmitted to the external service provider.
 */
exports.EmailTransmittedEvent = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        /** An id representing a batch of emails to be sent at the same time. */
        batchId: typebox_1.Type.String({ minLength: 32 }),
        /** The count of messages transmitted by the email service */
        count: typebox_1.Type.Number(),
        /** External service details used for processing the email sends */
        externalService: email_external_service_1.EmailExternalService,
    }),
    'detail-type': typebox_1.Type.Literal('EmailTransmitted'),
    source: message_source_1.EmailServiceSource,
    traceId: tracing_1.TraceId,
});
/**
 * Event Bridge message indicating there was an error creating a batch with the
 * external service during email transmission.
 */
exports.EmailBatchErrorEvent = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        /**
         * An id representing a batch of emails to be sent at the same time, this is
         * only present if provided as part of the triggering `EmailMessage` event.
         */
        batchId: typebox_1.Type.String({ minLength: 32 }),
    }),
    'detail-type': typebox_1.Type.Literal('EmailBatchError'),
    source: message_source_1.EmailServiceSource,
    traceId: tracing_1.TraceId,
});
/**
 * Event Bridge message indicating there was an error saving batch or message
 * information into the data store.
 */
exports.EmailStorageErrorEvent = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        /**
         * An id representing a batch of emails to be sent at the same time, this is
         * only present if provided as part of the triggering `EmailMessage` event.
         */
        batchId: typebox_1.Type.String({ minLength: 32 }),
        /** Error message from the `StorageError` */
        reason: typebox_1.Type.String(),
    }),
    'detail-type': typebox_1.Type.Literal('EmailStorageError'),
    source: message_source_1.EmailServiceSource,
    traceId: tracing_1.TraceId,
});
/**
 * Event Bridge message indicating there was an error transmitting the email
 * details to the external provider.
 */
exports.EmailTransmissionErrorEvent = typebox_1.Type.Object({
    detail: typebox_1.Type.Object({
        /** An id representing a batch of emails to be sent at the same time. */
        batchId: typebox_1.Type.String({ minLength: 32 }),
        /** The count of messages with errors from email service */
        errorCount: typebox_1.Type.Number(),
        /** List of error messages from the external service */
        errorMessages: typebox_1.Type.Array(typebox_1.Type.String()),
    }),
    'detail-type': typebox_1.Type.Literal('EmailTransmissionError'),
    source: message_source_1.EmailServiceSource,
    traceId: tracing_1.TraceId,
});
