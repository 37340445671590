"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailGuestSendStatus = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.EmailGuestSendStatus = typebox_1.Type.Union([
    typebox_1.Type.Object({ state: typebox_1.Type.Literal('draft') }),
    typebox_1.Type.Object({
        state: typebox_1.Type.Literal('pending'),
        /** Total number of messages in the batch */
        totalCount: typebox_1.Type.Number(),
    }),
    typebox_1.Type.Object({
        state: typebox_1.Type.Literal('processing'),
        /** Number of messages transmitted to the external provider */
        transmittedCount: typebox_1.Type.Number(),
        /** Total number of messages in the batch */
        totalCount: typebox_1.Type.Number(),
    }),
    typebox_1.Type.Object({
        state: typebox_1.Type.Literal('canceled'),
        /** Number of messages transmitted to the external provider */
        transmittedCount: typebox_1.Type.Number(),
        /** Total number of messages in the batch */
        totalCount: typebox_1.Type.Number(),
    }),
    typebox_1.Type.Object({
        state: typebox_1.Type.Literal('error'),
        /** Number of messages which failed to transmit */
        errorCount: typebox_1.Type.Number(),
        /** Error messages from the failed transmissions */
        errorMessages: typebox_1.Type.Array(typebox_1.Type.String()),
        /** Number of messages transmitted to the external provider */
        transmittedCount: typebox_1.Type.Number(),
        /** Total number of messages in the batch */
        totalCount: typebox_1.Type.Number(),
    }),
    typebox_1.Type.Object({
        state: typebox_1.Type.Literal('complete'),
        /** Total number of messages in the batch */
        totalCount: typebox_1.Type.Number(),
    }),
]);
