"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uuidRegExp = void 0;
exports.capitalizeFirstLetter = capitalizeFirstLetter;
exports.isDefined = isDefined;
exports.kebabToCamelCase = kebabToCamelCase;
exports.kebabToSnake = kebabToSnake;
exports.camelToKebabCase = camelToKebabCase;
exports.makeUriFriendly = makeUriFriendly;
exports.snakeToKebab = snakeToKebab;
exports.hashCode = hashCode;
exports.titleCase = titleCase;
exports.extractFileNameFromUri = extractFileNameFromUri;
exports.countWords = countWords;
exports.extractTextFromHTML = extractTextFromHTML;
const string_helpers_internal_1 = require("./string-helpers-internal");
exports.uuidRegExp = '[0-9a-f]{8}-[0-9a-f]{4}-[0-8][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}';
/**
 * Accepts a string and capitalizes its first letter. If the value is not a string,
 * the value provided, will be returned, unchanged
 * @param value - the string to update
 * @returns - the provided string with its first letter capitalized
 */
function capitalizeFirstLetter(value) {
    return typeof value === 'string'
        ? (value.charAt(0).toUpperCase() + value.slice(1))
        : value;
}
/**
 * Checks if the given value is defined and not empty.
 */
function isDefined(input) {
    return typeof input === 'string' && input !== '';
}
/**
 * Converts a kebab-cased string to a camelCased string.
 * @param kebabCase to be converted from kebab-case
 * @returns camelCased version of `kebabCase` input.
 */
function kebabToCamelCase(kebabCase) {
    return kebabCase.replace(/-[a-zA-Z]/g, (match) => match[1] ? match[1].toUpperCase() : '');
}
/**
 * Replaces kebabs (`-`) with snakes (`_`) in a string. This isn't a proper
 * conversion to snake case as it does not change the casing of any characters.
 */
function kebabToSnake(kebab) {
    return kebab.replaceAll('-', '_');
}
/**
 * Converts a camelCased string to a kebab-cased version. Always converts to
 * lowercase even if the input string is not kebab cased.
 *
 * **WARN** The type signature is naive. It works for simple CamelCased strings
 * that do not have sequential capital letters or spaces/underscores but breaks
 * down for more complex strings. Be aware the signature may be lying about the
 * return value.
 *
 * @param camelCase input to be converted from camelCase
 * @returns kebab-cased version of the `camelCase` input.
 */
function camelToKebabCase(camelCase) {
    const words = camelCase.split(/[_ -]/);
    const camelCaseRegex = /[A-Z]+/g;
    return words
        .filter((word) => word.length > 0)
        .map((word) => {
        const wordResult = word.replace(camelCaseRegex, (match, startIndex) => startIndex === 0 ? match.toLowerCase() : `-${match.toLowerCase()}`);
        return wordResult;
    })
        .join('-');
}
/**
 * Composes `stripNonUriChars` and `camelToKebabCase` to change the given
 * `input` into a URI friendly string.
 * @see camelToKebabCase
 * @see stripNonUriChars
 */
function makeUriFriendly(input) {
    return camelToKebabCase((0, string_helpers_internal_1.stripNonUriChars)(input));
}
/**
 * Replaces snakes (`_`) with kebabs (`-`) in a string. This isn't a proper
 * conversion to kebab case as it does not change the casing of any characters.
 */
function snakeToKebab(kebab) {
    return kebab.replaceAll('_', '-');
}
/**
 * Returns a 32bit integer hash code from a string
 * @see https://stackoverflow.com/a/8831937
 */
function hashCode(value) {
    let hash = 0;
    if (typeof value !== 'undefined') {
        for (let i = 0, len = value.length; i < len; i++) {
            const chr = value.charCodeAt(i);
            hash = (hash << 5) - hash + chr;
            hash |= 0; // Convert to 32bit integer
        }
    }
    return `${hash}`;
}
/**
 * Transforms a string to be Title Cased.
 * For example - "some example string" becomes "Some Example String"
 *
 * @param str The string to title case
 * @returns The string in Title Case
 */
function titleCase(str) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
        const subStr = splitStr[i];
        if (!subStr) {
            throw new Error(`Character at ${i} of ${str} is not defined.`);
        }
        splitStr[i] = subStr.charAt(0).toUpperCase() + subStr.substring(1);
    }
    return splitStr.join(' ');
}
/*
 * Extracts the filename from a full URI path.
 * For example - https://example.com/somefile.png becomes somefile.png
 *
 * @param uri The uri to parse
 * @returns The file name from the uri
 */
function extractFileNameFromUri(uri) {
    try {
        const url = new URL(uri);
        const segments = url.pathname.split('/');
        const lastSegment = segments[segments.length - 1];
        return lastSegment || undefined;
    }
    catch {
        return undefined;
    }
}
/**
 * Counts the number of individual words in a string. A word is considered
 * any set of one or more characters, bounded by a space, or a hashtag followed
 * by characters.
 *
 * @param str The string to count words in
 * @returns the number of words in the string
 */
function countWords(str) {
    let s = str.replace(/(^\s*)|(\s*$)/gi, ''); //exclude start and end white-space
    s = s.replace(/[ ]{2,}/gi, ' '); // 2 or more space to 1
    s = s.replace(/\n /, '\n'); // exclude newline with a start spacing
    const words = s.split(' ').filter((str) => str !== '');
    return words.length;
}
/**
 * Extracts all text content between HTML tags and returns them as an array.
 *
 * @param htmlString The HTML string to extract text from
 * @returns An array of all text contained between HTML tags
 */
function extractTextFromHTML(htmlString) {
    if (typeof document !== 'undefined') {
        // In browser environments, take advantage of the native DOMParser class.
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const textNodes = [];
        const walker = document.createTreeWalker(doc.body, NodeFilter.SHOW_TEXT, null);
        let node = walker.nextNode();
        while (node !== null) {
            const text = node.textContent?.trim();
            if (text) {
                textNodes.push(text);
            }
            node = walker.nextNode();
        }
        return textNodes;
    }
    else {
        // In server environments, use a more simplified regex approach
        return htmlString
            .replace(/<[^>]*>/g, '\n') // Replace HTML tags with newlines
            .split('\n') // Split by newlines
            .map((text) => text.trim()) // Trim whitespace
            .filter((text) => text.length > 0); // Remove empty strings
    }
}
