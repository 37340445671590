"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailGuestSend = void 0;
const typebox_1 = require("@sinclair/typebox");
const timestamp_json_1 = require("../timestamp-json");
const model_helpers_1 = require("../model-helpers");
const email_external_service_1 = require("./email-external-service");
const email_guest_send_status_1 = require("./email-guest-send-status");
/**
 * Represents a record identifying emails sent to guests of a site.
 */
exports.EmailGuestSend = typebox_1.Type.Object({
    /** Identifier for the record */
    id: typebox_1.Type.String({ format: 'uuid' }),
    /** Identifier for the `Site` with the guest list for emails */
    siteId: typebox_1.Type.String({ format: 'uuid' }),
    /** Filters applied to the `Site` guest list */
    criteria: (0, model_helpers_1.JsonObject)({}),
    /** External service details used for processing the email sends */
    externalService: typebox_1.Type.Optional(email_external_service_1.EmailExternalService),
    /**
     * Status of the email send and details relevant to the state of the email
     * transmission
     */
    status: email_guest_send_status_1.EmailGuestSendStatus,
    ...timestamp_json_1.TimestampsJson.properties,
}, { $id: 'EmailGuestSend' });
