"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailExternalService = exports.EmailServiceType = void 0;
const typebox_1 = require("@sinclair/typebox");
/** Represents services which may be used for transmitting email */
exports.EmailServiceType = typebox_1.Type.Union([typebox_1.Type.Literal('sendgrid')]);
/**
 * Identifies service information for how an email or group of emails were
 * transmitted.
 */
exports.EmailExternalService = typebox_1.Type.Union([
    typebox_1.Type.Object({
        /** Identifier for the external batch */
        sendgridBatchId: typebox_1.Type.String(),
        /** Identifies a batch of emails were transmitted to sendgrid */
        externalServiceType: typebox_1.Type.Literal('sendgrid'),
    }),
    typebox_1.Type.Object({
        /** Identifies an unbatched email was transmitted to sendgrid */
        externalServiceType: typebox_1.Type.Literal('sendgrid-single'),
    }),
]);
